import {
	UniversityProfile,
	UniversityCourseDetails,
	UniversityDetails,
	Product,
	ProductEnum,
} from '@uc/web/shared/data-models';

type ExternalLink =
	| 'external_url'
	| 'cover_image'
	| 'external_prospectus_url'
	| 'external_events_url';

type ExternalLinks = {
	[key in ExternalLink]: string;
};

export const getDetailByProduct = (
	uni: Partial<UniversityProfile> | UniversityCourseDetails | UniversityDetails,
	key: ExternalLink,
	product: Product | null,
) => {
	const { undergraduate, postgraduate } = uni;
	const ugLink = undergraduate ? undergraduate[key] : null;
	const pgLink = postgraduate ? postgraduate[key] : null;

	if (!product) {
		return ugLink || pgLink || '';
	} else if (product === ProductEnum.Postgraduate) {
		return pgLink || '';
	} else {
		return ugLink || '';
	}
};

export const getExternalLinks = (
	uni: Partial<UniversityProfile> | UniversityCourseDetails | UniversityDetails,
	links: ExternalLink[],
	product: Product,
) => {
	const res: ExternalLinks = {} as ExternalLinks;

	links.forEach((link) => {
		res[link] = getDetailByProduct(uni, link, product);
	});

	return res;
};
