import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
declare let adngin: any;

@Injectable({
	providedIn: 'root',
})
export class SnigelService {
	private _currentCatId = 0;
	private _platformId = inject(PLATFORM_ID);

	readonly regex = /<h2>(.*?)<\/h2>/gim;

	readonly inContentAd1 = `<div class="adngin">
    <!-- mobile add -->
    <div id="adngin-incontent_3_mob-0" class="incontent_3_mob-0"></div>
    <!-- desktop add -->
    <div id="adngin-incontent_3_dskt-0" class="incontent_3_dskt-0"></div>
    </div>`;

	readonly inContentAd2 = `<div class="adngin">
    <!-- mobile add -->
    <div id="adngin-incontent_5_mob-0" class="incontent_3_mob-0"></div>
    <!-- desktop add -->
    <div id="adngin-incontent_5_dskt-0" class="incontent_3_dskt-0"></div>
    </div>`;

	readonly inContentAd3 = `<div class="adngin">
        <!-- mobile add -->
        <div id="adngin-incontent_6_mob-0" class="incontent_3_mob-0"></div>
        <!-- desktop add -->
        <div id="adngin-incontent_6_dskt-0" class="incontent_3_dskt-0"></div>
        </div>`;

	readonly allAds = [this.inContentAd1, this.inContentAd2, this.inContentAd3];

	getAds(array: string[]) {
		if (
			isPlatformBrowser(this._platformId) &&
			(window as any).adngin &&
			(window as any).adngin.adnginLoaderReady
		) {
			adngin.queue.push(function () {
				adngin.cmd.startAuction(array);
			});
		}
	}

	updateCustomTargeting(category: number) {
		if (category === this._currentCatId) return;
		this._currentCatId = category;
		this._updateSnigelPubConf(category);
	}

	//inserts ads into content of articles and guides
	insertAdsIntoContent = (content: string) => {
		if (!content) return;

		const count = this.countTitles(content);
		const divisor = this.getDivisor(count);
		const insertBefore = this.calculateInsertBeforeNth(count, divisor);
		return this.editText(content, insertBefore);
	};

	//counts how many h2s are present in content
	countTitles(content: string): number {
		return ((content || '').match(this.regex) || []).length;
	}

	//depending on how many <h2>s are present, divisor changes
	getDivisor(count: number): number {
		return count < 5 ? 2 : count < 9 ? 3 : 4;
	}

	//calculates before which nth <h2> ad should be inserted.
	calculateInsertBeforeNth(count: number, divisor: number): number {
		const factor = count / divisor;
		const roundedFactor = Math.round(factor);
		const outOfRange = roundedFactor * divisor > count;

		if (count < 16) {
			return outOfRange ? roundedFactor : roundedFactor + 1;
		} else {
			return outOfRange ? roundedFactor - 1 : roundedFactor;
		}
	}

	//ads html markup before the nth <h2> element
	editText(content: string, insertBefore: number): string {
		let i = 1;
		let insertAdOfType = 0;

		const editedContent = content.replace(this.regex, (fullMatch) => {
			let html = null;

			if (i % insertBefore === 0 && this.allAds[insertAdOfType]) {
				html = `${this.allAds[insertAdOfType]}${fullMatch}`;
				insertAdOfType++;
			} else {
				html = fullMatch;
			}

			i++;
			return html;
		});

		return editedContent;
	}

	private _updateSnigelPubConf(category: number) {
		if (isPlatformBrowser(this._platformId)) {
			const win = window as any;
			const targeting = win.snigelPubConf?.adengine?.targeting;
			if (targeting) {
				win.snigelPubConf.adengine.targeting.key1 = category;
			}
		}
	}
}

/*
    ** DOCS ** 
    
    AD UNIT NAME	        DIV ELEMENT	                                        MAX SIZE (px)	    DEVICE
    bottom_adhesive_dskt	<div id="adngin-bottom_adhesive_dskt-0"></div>	    970x90	            dskt & tablet
    bottom_adhesive_mob	    <div id="adngin-bottom_adhesive_mob-0"></div>	    320x50	            mobile
    
    incontent_1_dskt	    <div id="adngin-incontent_1_dskt-0"></div>	        970x250	            dskt & tablet
    incontent_2_dskt	    <div id="adngin-incontent_2_dskt-0"></div>	        970x250	            dskt & tablet
    incontent_4_dskt	    <div id="adngin-incontent_4_dskt-0"></div>	        300x600	            dskt
    incontent_1_mob	        <div id="adngin-incontent_1_mob-0"></div>	        300x250	            mobile
    incontent_2_mob	        <div id="adngin-incontent_2_mob-0"></div>	        300x250	            mobile
    
	incontent_3_mob	        <div id="adngin-incontent_3_mob-0"></div>	        300x250	            mobile
	incontent_3_dskt	    <div id="adngin-incontent_3_dskt-0"></div>	        750x250	            dskt & tablet
	incontent_5_mob	        <div id="adngin-incontent_5_mob-0"></div>	        300x250	            mobile
    incontent_5_dskt	    <div id="adngin-incontent_5_dskt-0"></div>	        750x250	            dskt & tablet
    incontent_6_mob	        <div id="adngin-incontent_6_mob-0"></div>	        300x250	            mobile
	incontent_6_dskt	    <div id="adngin-incontent_6_dskt-0"></div>	        750x250	            dskt & tablet

    STAGING: 
    <script async data-cfasync="false" src="https://staging-cdn.snigelweb.com/adengine/universitycompare.com/loader.js" type="text/javascript"></script>

    PROD: 
    <script async data-cfasync="false" src="https://cdn.snigelweb.com/adengine/universitycompare.com/loader.js"
		type="text/javascript"></script>


    ID #ad_stop: to be added to elements following sticky ad with id #adngin-incontent_4_dskt-0 
    This ensures that sticky ad doesn't overflow component
*/
